import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import arteryaIcon from "../images/circleIcon.png";
import { useState } from "react";

const Dock = () => {
	const [sm, setSm] = useState("12");
	window.addEventListener("scroll", () => {
		const scrollPosition = window.scrollY;
		if (scrollPosition > 50) {
			setSm("6");
		} else {
			setSm("12");
		}
	});
	return (
		<Row className="justify-content-around sticky-top mx-1">
			<Col sm={sm} className="transition-05">
				<Navbar
					className="background-navbar my-1 round-semicircle fs-5"
					expand="lg"
					collapseOnSelect>
					<Container fluid>
						<Navbar.Brand href="/">
							<img src={arteryaIcon} alt="Arterya" width="50" height="50" />
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="MainNavbar" className="border border-0">
							<i className="fa-solid fa-bars fs-4"></i>
						</Navbar.Toggle>
						<Navbar.Collapse id="mainNavbar">
							<Row className="justify-content-around">
								<Col md="1">
									<Nav className="me-auto my-2 my-lg-0" navbarScroll>
										<Nav.Link href="/proyectos">Proyectos</Nav.Link>
										<Nav.Link href="/blog">Blog</Nav.Link>
										<Nav.Link href="/contacto">Contactanos</Nav.Link>
									</Nav>
								</Col>
							</Row>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</Col>
		</Row>
	);
};

export default Dock;
