import { Route, Switch } from "wouter";
import Dock from "././components/Dock";
//import Home from "././components/routes/Home";
import Temp from "./components/routes/temp/tempBuildingPage";
import Footer from "./components/Footer";

function App() {
	return (
		<div className="container-fluid">
			<Dock />

			<Switch>
				<Route path="/" component={Temp} />
				<Route path="/proyectos" component={Temp} />
				<Route path="/contacto" component={Temp} />
				<Route path="/*" component={Temp} />
			</Switch>

			{/* <Footer /> */}
		</div>
	);
}

export default App;
