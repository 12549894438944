import { Row, Col } from "react-bootstrap";

const Temp = () => {
	return (
		<div className="container text-center">
			<Row className="justify-content-around">
				<Col md="4">
					<lottie-player
						src="https://lottie.host/0e880443-9850-4496-aabc-0e4077d981d5/ox5nrFRaaR.json"
						background="transparent"
						speed="1"
						style={{ width: 300 + "px", height: 300 + "px" }}
						direction="1"
						class="mx-5"
						mode="normal"
						loop
						autoplay></lottie-player>
				</Col>
			</Row>
			<p className="fs-1">
				Estamos trabajando para brindarte la mejor experiencia.
			</p>
			<p className="fs-1">Esperalo pronto...</p>
		</div>
	);
};

export default Temp;
